"use client";
import { useEffect, lazy, Suspense } from "react";
import MainScreen from "../mainScreen/MainScreen";
import { AppHeader } from "../appHeader/AppHeader";
import { AppFooter } from "../appFooter/AppFooter";

import Lenis from "lenis";
import gsap from "gsap";

window.gsap = gsap;

const FeaturedWorks = lazy(() => import("../featuredWorks/FeaturedWorks"));
const ThatLooksGood = lazy(() => import("../thatLooksGood/ThatLooksGood"));
const WhoRiddle = lazy(() => import("../whoRiddle/WhoRiddle"));
const TeachTok = lazy(() => import("../teachTok/TeachTok"));
const OurServices = lazy(() => import("../ourServices/OurServices"));
const OurTechnologies = lazy(
  () => import("../ourTechnologies/OurTechnologies")
);
const CaseStudies = lazy(() => import("../caseStudies/CaseStudies"));
const PopDoc = lazy(() => import("../popDoc/PopDoc"));

const App: React.FC = () => {
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.7,
      // @ts-ignore
      smooth: true,
      touchMultiplier: 0,
    });

    function raf(time: any) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <>
      <MainScreen />
      <AppHeader />

      <Suspense fallback={<div>Loading...</div>}>
        <FeaturedWorks />
        <ThatLooksGood />
        <WhoRiddle />
        <TeachTok />
        <PopDoc />
        <OurServices />
        <OurTechnologies />
        <CaseStudies />
        <AppFooter />
      </Suspense>
    </>
  );
};

export default App;

import { useState, useEffect, memo } from "react";
import { VideoPlayer } from "../../ui/videoPlayer/videoPlayer";
import Modal from "../modal/Modal";
import OpenModalButton from "../../ui/openModalButton/OpenModalButton";
import "./appFooter.scss";


export const AppFooter: React.FC = memo(() => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 480);
  const [modalActive, setModalActive] = useState(false);

  const handleClick = () => setModalActive(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(
        () => setIsSmallScreen(window.innerWidth <= 480),
        200
      );
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <footer className="footer">
        <hr />
        <section className="footer__cta">
          <div className="footer__vision">
            <p>How about making your vision real?</p>
          </div>
          <div className="footer__contact">
            <div onClick={handleClick}>
              <OpenModalButton variant="light" url="#!">
                Let's talk
              </OpenModalButton>
            </div>
          </div>
        </section>
        <hr />
        <section className="footer__content">
          <div className="footer__info">
            <div className="footer__address-container">
              <address className="footer__address">
                <p>Hoża 86/410 Warsaw, Poland 00-682</p>
              </address>
              <address className="footer__address">
                <p>Súkennícka 1 Bratislava, Slovakia 1821-09</p>
              </address>
            </div>
            <nav aria-label="Footer navigation" className="footer__links">
            <div className="footer__links-sitemap">
                <p>Sitemap</p>
                <ul>
                  <li>
                    <a href="#!">Services</a>
                  </li>
                  <li>
                    <a href="#!">About</a>
                  </li>
                  <li>
                    <a href="#!">Works</a>
                  </li>
                </ul>
              </div>
              <div className="footer__links-socials">
                <p>Socials</p>
                <ul>
                  <li>
                    <a href="#!">Instagram</a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/kilona/posts/?feedView=all">
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer__links-legal">
                <p>Legal</p>
                <ul>
                  <li>
                    <a href="#!">Terms</a>
                  </li>
                  <li>
                    <a href="#!">Privacy</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="footer__copyright">
            <VideoPlayer src="main_screen_csvqxh" fetchpriority="low"/>
            <p>Copyright © Kilona 2024</p>
          </div>
        </section>
      </footer>
      <Modal active={modalActive} setActive={setModalActive} />
    </>
  );
});


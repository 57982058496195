import { Cloudinary } from "@cloudinary/url-gen";
import { memo, Suspense} from "react";
import { AdvancedVideo } from "@cloudinary/react";

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
  }
});
interface VideoPlayerProps {
    src: string;
    controls?: boolean;
    autoPlay?: boolean;
    loop?: boolean;
    playsInline?: boolean;
    className?: string;
    muted?: boolean;
    fetchpriority?: 'high' | 'low' | 'auto';
  }
export const VideoPlayer:  React.FC<VideoPlayerProps> = memo(({className, src, autoPlay = true, loop = true, playsInline = true, controls = false, muted = true, fetchpriority = 'low'}) => {
  const videoUrl = cld.video(src)
    .format('auto')
    .quality('auto')

    const posterUrl = cld.video(src)
    .format('auto')
    .quality('auto')
    .addTransformation('so_0')
    .toURL();
  
return (
    <Suspense fallback={<div>Loading video...</div>}>
    <AdvancedVideo
        cldVid={videoUrl}
        className={className}
        autoPlay={autoPlay}
        loop={loop}
        playsInline={playsInline}
        controls={controls}
        poster={posterUrl}
        muted={muted}
        fetchpriority={fetchpriority}
    />
    </Suspense>
   );
});
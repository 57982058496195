import { useState } from "react";
import Modal from "../modal/Modal";
import "./mobileBurger.scss";
import logo from "../../resources/images/logo.svg";

interface BurgerProps {
  active: boolean;
  setActive: (active: boolean) => void;
}

const MobileBurger: React.FC<BurgerProps> = ({ active, setActive }) => {
  const [modalActive, setModalActive] = useState(false);

  const handleClose = () => setActive(false);
  const openModal = () => setModalActive(true);

  const navItems = [
    { href: "#work", label: "Work" },
    { href: "#services", label: "Services" },
    { href: "#tech-stack", label: "Tech Stack" },
  ];

  return (
    <>
      <section className={`burger ${active ? "burger--visible" : ""}`}>
        <header className="burger-header">
          <div className="burger__logo-container">
            <img src={logo} alt="Kilona Logo" className="burger__logo" />
          </div>
          <button
            className="burger-close"
            aria-label="Close menu"
            onClick={handleClose}
          >
            <span aria-hidden="true" className="burger-close_cross">
              ╳
            </span>
          </button>
        </header>
        <main className="burger-main">
          <nav className="burger-info">

            <ul className="burger__nav-list">
              {navItems.map((item) => (
                <li key={item.href}>
                  <a
                    href={item.href}
                    className="burger__nav-link"
                    onClick={handleClose}
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>

          </nav>
          <div className="burger__cta-container">
            <button className="burger__cta" onClick={openModal}>
              Let's talk
            </button>
          </div>
          <footer className="burger__copyright">
            <p>© Kilona 2024</p>
          </footer>
        </main>
      </section>

      <Modal active={modalActive} setActive={setModalActive} />
    </>
  );
};

export default MobileBurger;
import { memo, useState } from "react";
import logo from "../../resources/images/logo.svg";

import Modal from "../modal/Modal";
import MobileBurger from "../mobileBurger/MobileBurger";

import "./appHeader.scss";

export const AppHeader: React.FC = memo(() => {
  const [modalActive, setModalActive] = useState(false);
  const [burgerActive, setBurgerActive] = useState(false);

  const handleClick = () => {
    setModalActive(true);
  };

  const navLinks = [
    { text: "Work", href: "#work" },
    { text: "Services", href: "#services" },
    { text: "Tech Stack", href: "#tech-stack" },
  ];

  return (
    <>
      <header className="header">
        <div className="header__logo-container">
          <img src={logo} alt="logo" className="header__logo" />
        </div>

        <div
          className="header__nav-menu"
          onClick={() => setBurgerActive(true)}
        />

        <nav className="header__nav" aria-label="Primary navigation">
          <ul className="header__nav-list">
            {navLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href} className="header__nav-link">
                  {link.text}
                </a>
              </li>
            ))}
            <li>
              <button
                className="header__nav-link header__nav-link-cta"
                onClick={handleClick}
              >
                Let's talk
              </button>
            </li>
          </ul>
        </nav>
      </header>

      <Modal active={modalActive} setActive={setModalActive} />
      <MobileBurger active={burgerActive} setActive={setBurgerActive} />
    </>
  );
});



import "./openModalButton.scss";

interface ModalButtonProps {
  variant: "light" | "dark";
  url: string;
  children: React.ReactNode;
}

const OpenModalButton: React.FC<ModalButtonProps> = ({
  children,
  variant,
  url,
}) => {
  return (
    <a href={url} className={`open-modal__button ${variant}`}>
      {children} <span>→</span>
    </a>
  );
};

export default OpenModalButton;

import { useState, useEffect } from "react";
import { VideoPlayer } from "../../ui/videoPlayer/videoPlayer";

import emailjs from "@emailjs/browser";
import checkmark from "../../resources/images/checkmark.svg";
import "./contactForm.scss";

interface ContactFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  setResetForm: React.Dispatch<React.SetStateAction<() => void>>;
}

const ContactForm: React.FC<ContactFormProps> = ({ formRef, setResetForm }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({
    email: "",
  });

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const email = formRef.current?.["user_email"].value.trim();

    let errors = { email: "" };
    let isValid = true;

    if (!email) {
      errors.email = "Email is required.";
      isValid = false;
    } else if (!validateEmail(email)) {
      errors.email = "Please enter a valid email address.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (formRef.current) {
      emailjs
        .sendForm("service_7hu8beg", "template_5xfu43w", formRef.current, {
          publicKey: "QZzeM-wUznnaeWgXy",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            setIsSubmitted(true);
            (e.target as HTMLFormElement).reset();
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    }
  };

  useEffect(() => {
    setResetForm(() => {
      return () => {
        setIsSubmitted(false);
      };
    });
  }, [setResetForm]);

  return (
    <>
      {isSubmitted ? (
        <div className="success-message">
          <div className="success__hero-video">
            <div className="success__video-container">
              <VideoPlayer src="main_screen_csvqxh"/>
              <div className="success__video-checkmark">
                <img src={checkmark} alt="checkmark" />
              </div>
            </div>
          </div>
          <p className="success__reach">Thank You for Reaching Out!</p>
          <p className="success__touch">We'll get in touch with you shortly.</p>
        </div>
      ) : (
        <>
          <p className="modal__message">Or leave us a message</p>

          <form ref={formRef} onSubmit={sendEmail}>
            <input type="text" name="user_name" placeholder="Name" required />

            <input
              type="email"
              name="user_email"
              placeholder="Email"
              required
            />
            {formErrors.email && <p className="error">{formErrors.email}</p>}

            <input
              type="text"
              name="user_subject"
              placeholder="Subject"
              required
            />
            <textarea
              name="message"
              rows={3}
              placeholder="Describe your project..."
              required
            />
            <button type="submit">
              Submit<span>→</span>
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default ContactForm;

import ReactDOM from "react-dom/client";
import "./style/style.scss";
import App from "./components/app/App";
import './fonts.js';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      
      const registrations = await navigator.serviceWorker.getRegistrations();
      for(let registration of registrations) {
        await registration.unregister();
      }
      const registration = await navigator.serviceWorker.register('/serviceWorker.js');
     
      registration.update();
      
    } catch (error) {
      }
  });
}


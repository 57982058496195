import { memo, useEffect, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { VideoPlayer } from "../../ui/videoPlayer/videoPlayer";
import gsap from "gsap";

import Modal from "../modal/Modal";
import "./mainScreen.scss";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const MainScreen: React.FC = () => {
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    gsap.set(".main-screen__video", { filter: "blur(0)" });
    gsap.to(".main-screen__video", {
      filter: "blur(15px)",
      scrollTrigger: {
        trigger: ".main-screen",
        start: "bottom center",
        end: "bottom top",
        scrub: true,
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <section className="main-screen">
        <VideoPlayer
          className="main-screen__video"
          src="main_screen_csvqxh"
          fetchpriority="low"
        />
        <div className="main-screen__footer">
          <div className="main-screen__headings">
            <p>
              Full-cycle product development for Mobile, VR, and Web, tailored
              to idea-stage, pre-seed, seed, and Series A startups
            </p>
          </div>
          <div className="main-screen__scroll">
            <p>Scroll to read</p>
          </div>
        </div>

        <div className="main-screen__cta-container">
          <button
            className="main-screen__cta"
            onClick={() => setModalActive(true)}
          >
            Let's talk
          </button>
        </div>
      </section>

      <Modal active={modalActive} setActive={setModalActive} />
    </>
  );
};

export default memo(MainScreen);

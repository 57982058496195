import { useRef, useState } from "react";
import "./modal.scss";

import linkedin from "../../resources/images/linkedin.svg";
import insta from "../../resources/images/instagram.svg";

import ContactForm from "../contactForm/ContactForm";

interface ModalProps {
  active: boolean;
  setActive: (active: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({ active, setActive }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [resetForm, setResetForm] = useState<() => void>(() => {});

  const closeModal = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    resetForm();
    setActive(false);
  };

  return (
    <section
      className={`modal ${active ? "modal--visible" : ""}`}
      data-lenis-prevent
    >
      <div className="modal-header">
        <p className="modal-cta">
          <span className="modal-cta_text">
            Have a project in mind, or looking for the perfect partner to bring
            your ideas to life?
          </span>{" "}
          <span className="modal-cta_mobile">Start a project</span>
        </p>
        <button className="modal-close" onClick={closeModal}>
          <span className="modal-close_text">Close</span>{" "}
          <span className="modal-close_cross">╳</span>
        </button>
      </div>
      <div className="modal-main">
        <div className="modal-info">
          <div className="modal-contact">
            <a href="#!">stepan@kilona.io</a>
            <a href="https://calendly.com/stepan-kudelya/30min">Book a call</a>
          </div>
          <div className="modal-addresses">
            <p>Hoża 86/410 Warsaw, Poland 00-682</p>
            <p>Súkennícka 1, Bratislava, Slovakia 1821-09</p>
          </div>
        </div>
        <a
          href="https://calendly.com/stepan-kudelya/30min"
          className="modal__call-mobile"
        >
          Book a call
        </a>

        <ContactForm formRef={formRef} setResetForm={setResetForm} />
        <div className="modal-contact_mobile">
          <a href="#!">contact@kilona.io</a>
          <a href="#!">stepan@kilona.io</a>
        </div>
        <div className="modal-contact_social">
          <a href="#!">
            <img src={insta} alt="inst" className="modal-contact_social-link" />
          </a>
          <a href="https://www.linkedin.com/company/kilona/">
            <img
              src={linkedin}
              alt="linkedin"
              className="modal-contact_social-link"
            />
          </a>
        </div>
        <div className="modal-addresses_mobile">
          <p>Hoża 86/410 Warsaw, Poland 00-682</p>
          <p>Súkennícka 1, Bratislava, Slovakia 1821-09</p>
        </div>
      </div>
    </section>
  );
};

export default Modal;
